import { Button, Collapse } from '@msdyn365-commerce-modules/utilities';
import classnames from 'classnames';
import * as React from 'react';
import { ICollapseProps } from '../components/citta-drawer.props';
import { IDrawerProps } from './citta-drawer.props';

export interface IDrawerState {
    isOpen: boolean;
}

export type GlyphPlacement = 'start' | 'end';

/**
 * Drawer - This is a full width uncontrolled collapse where the button glyph changes
 * when the drawer is open and closed
 */
export default class Drawer extends React.Component<IDrawerProps, IDrawerState> {
    public state: IDrawerState;
    private drawerOpenGlyph: string;
    private drawerCloseGlyph: string;
    private drawerGlyphClass: string;
    private isManuallyToggled: boolean;

    constructor(props: IDrawerProps) {
        super(props);
        this._toggle = this._toggle.bind(this);
        this.isManuallyToggled = false;
        this.state = { isOpen: (props.collapseProps && props.collapseProps.isOpen) || false };
        this.drawerGlyphClass = classnames('drawer__glyph', this.props.toggleGlyphClassName ? this.props.toggleGlyphClassName : '');
        if (this.props.animateGlyph) {
            this.drawerOpenGlyph = classnames(this.drawerGlyphClass,
                // tslint:disable-next-line:align
                this.props.openGlyph ?
                    this.props.openGlyph :
                    this.props.closeGlyph ?
                        this.props.closeGlyph : '');
            this.drawerCloseGlyph = this.drawerOpenGlyph;
        } else {
            this.drawerOpenGlyph = classnames(this.drawerGlyphClass,
                // tslint:disable-next-line:align
                this.props.openGlyph ?
                    this.props.openGlyph : '');
            this.drawerCloseGlyph = classnames(this.drawerGlyphClass,
                // tslint:disable-next-line:align
                this.props.closeGlyph ?
                    this.props.closeGlyph : '');
        }
    }

    public render(): JSX.Element {
        const { selectedStateOptionText } = this.props;
        const collapseProps = { ...this.props.collapseProps } as ICollapseProps;
        // If the drawer is manually toggled by pressing the button, then we use the internal state
        if (collapseProps.isOpen === undefined || this.isManuallyToggled) {
            collapseProps.isOpen = this.state.isOpen;
        } else if (!collapseProps.isOpen) {
            this.state.isOpen = collapseProps.isOpen;
        }
        collapseProps.children = this.props.children;
        const drawerClass = classnames('drawer', this.props.className ? this.props.className : '');
        this.isManuallyToggled = false;
        return (
            <div className={drawerClass}>
                <Button
                    {...this.props.toggleButtonProps}
                    onClick={this._toggle}
                    aria-expanded={collapseProps.isOpen}
                    block
                    disabled={this.props.disabled}
                    className={'drawer__button'}
                    color='secondary'
                    tabIndex={-1}
                >
                    {this.props && this.props.radioButtonOption ? (
                        <div className='c-fauxRadio'>
                            <input
                                type='radio'
                                role='radiogroup'
                                id={this.props.toggleButtonText.toString()}
                                onClick={this._toggle}
                                checked={this.props.toggleButtonText.toString() === selectedStateOptionText}
                            />
                            {/* tslint:disable-next-line: jsx-self-close */}
                            <label
                                htmlFor={this.props.toggleButtonText.toString()}
                                className='fauxRadio'
                            // tslint:disable-next-line:jsx-alignment
                            ></label>
                        </div>
                    ) : null}
                    {this._generateDrawerButtonInnerMarkup(collapseProps.isOpen, this.props.openInNewTab || false)}
                </Button>
                <Collapse {...collapseProps} />
            </div>
        );
    }

    private _generateDrawerButtonInnerMarkup(collapseState: boolean, openInNewTab: boolean): JSX.Element {
        const drawerButtonTextClass = classnames('drawer__buttontext',
            // tslint:disable-next-line:align
            this.props.toggleButtonClassName ?
                this.props.toggleButtonClassName : '');
        if (this.props.glyphPlacement === 'end') {
            if (this.props.toggleButtonTextClick) {
                return (
                    <>
                        <a
                            onMouseOver={this.props.onCategoryMouseOver}
                            onClick={this._clicFunction}
                            href={this.props.toggleButtonUrl}
                            role='menuitem'
                            target={openInNewTab ? '_blank' : undefined}
                            className={classnames(drawerButtonTextClass, '__start')}
                        >
                            {this.props.toggleButtonText}
                        </a>
                        {this._generateGlyphMarkup('drawer__glyph__end', collapseState)}
                    </>
                );
            } else {
                return (
                    <>
                        <span className={classnames(drawerButtonTextClass, '__start')}>{this.props.toggleButtonText}</span>
                        {this._generateGlyphMarkup('drawer__glyph__end', collapseState)}
                    </>
                );
            }
        }

        if (this.props.glyphPlacement === 'start') {
            if (this.props.toggleButtonTextClick) {
                return (
                    <>
                        {this._generateGlyphMarkup('drawer__glyph__start', collapseState)}
                        <a onMouseOver={this.props.onCategoryMouseOver} role='menuitem' href={this.props.toggleButtonUrl} className={classnames(drawerButtonTextClass, '__end')}>{this.props.toggleButtonText}</a>
                    </>
                );
            } else {
                return (
                    <>
                        {this._generateGlyphMarkup('drawer__glyph__start', collapseState)}
                        <span className={classnames(drawerButtonTextClass, '__end')}>{this.props.toggleButtonText}</span>
                    </>
                );
            }
        } else {
            if (this.props.toggleButtonTextClick) {
                return <a tabIndex={0} onMouseOver={this.props.onCategoryMouseOver} href={this.props.toggleButtonUrl} role='menuitem' className={classnames(drawerButtonTextClass, '__start')}>{this.props.toggleButtonText}</a>;
            } else {
                return <span className={classnames(drawerButtonTextClass, '__start')}>{this.props.toggleButtonText}</span>;
            }
        }
    }
    private _clicFunction = (event: React.MouseEvent<HTMLAnchorElement>): void => {
        event.preventDefault();
        if (this.props.toggleButtonUrl) {
            window.location.assign(this.props.toggleButtonUrl);
        }
    }
    private _generateGlyphMarkup(location: string, collapseState: boolean): JSX.Element {
        let glyphClass = collapseState ? this.drawerOpenGlyph : this.drawerCloseGlyph;
        glyphClass = `${glyphClass} ${location}`;
        return (
            <span tabIndex={0} className={glyphClass} />
        );
    }

    private _toggle(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void {
        // @ts-ignore
        if (e.target.tagName.toLowerCase() !== 'a') {
            e.preventDefault();
            this.setState({ isOpen: !this.state.isOpen });
            this.isManuallyToggled = true;
            if (this.props.onToggle) {
                this.props.onToggle(this.state);
            }
        }

    }
}

export { Drawer };
